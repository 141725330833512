<template>
  <b-form @submit.prevent="onSubmit">
    <hs-group>
      <label for="title" class="font-weight-bold">{{ $t('sparkmembers.offers.form.title') }}</label>
      <hs-input
        name="title"
        id="title"
        type="text"
        v-model="form.title"
        :disabled="loading"
        :placeholder="$t('sparkmembers.offers.form.title')"
        :text="$t('sparkmembers.offers.form.title-helper')"
      />
    </hs-group>

    <hs-group>
      <label for="facebook_pixel" class="font-weight-bold">{{ $t('sparkmembers.offers.form.facebook-pixel') }}</label>
      <hs-input
        name="facebook_pixel"
        id="facebook_pixel"
        type="text"
        v-model="form.facebook_pixel"
        :disabled="loading"
        :placeholder="$t('sparkmembers.offers.form.facebook-pixel-placeholder')"
      />
      <a
        href="https://ajuda.herospark.com/support/solutions/articles/69000090391-como-configurar-o-facebook-pixel-"
        target="_blank"
        class="text-primary font-size-xs"
      >
        {{ $t('sparkmembers.offers.form.facebook-pixel-helper') }}
      </a>
    </hs-group>
    <hs-group v-if="mustShowGAField">
      <label for="ga_tracking_id" class="font-weight-bold">{{ $t('sparkmembers.offers.form.ga-tracking-id') }}</label>
      <hs-input
        name="ga_tracking_id"
        id="ga_tracking_id"
        type="text"
        v-model="form.ga_tracking_id"
        :disabled="loading"
        :placeholder="$t('sparkmembers.offers.form.ga-tracking-id-placeholder')"
      />
    </hs-group>

    <div v-if="showPayment">
      <div class="my-4">
        <hs-group>
          <label for="price" class="font-weight-bold">{{ $t('sparkmembers.offers.form.price') }}</label>
          <div class="w-25 mb-3">
            <money
              id="price"
              name="price"
              v-model="form.price"
              class="form-control"
              :disabled="loading"
              v-if="!form.promotional"
              prefix="R$ "
              decimal=","
              thousands="."
              :masked="false"
            />
            <money
              v-model="form.base_price"
              class="form-control"
              :disabled="loading"
              v-if="form.promotional"
              prefix="R$"
              decimal=","
              thousands="."
              :masked="false"
            />
          </div>
          <div class="w-100 mb-3 text-danger" v-if="form.price < 1.99">
            {{ $t('sparkmembers.offers.form.price-minimum') }}
          </div>
          <hs-checkbox v-model="form.promotional" :disabled="loading">
            {{ $t('sparkmembers.offers.form.promotional') }}
          </hs-checkbox>
        </hs-group>

        <div class="mt-4" v-if="form.promotional">
          <hs-group>
            <label for="promotional" class="font-weight-bold">{{
              $t('sparkmembers.offers.form.price-promotional')
            }}</label>
            <div class="w-100 d-flex align-items-center">
              <div class="w-25">
                <money
                  id="promotional"
                  v-model="form.price"
                  class="form-control"
                  :disabled="!form.promotional || loading"
                  prefix="R$"
                  decimal=","
                  thousands="."
                  :masked="false"
                />
              </div>
              <div class="d-flex flex-column ml-5 pl-4 border-primary border-left">
                <span class="font-size-xs text-muted">
                  {{ $t('sparkmembers.offers.form.from') }} <s>R$ {{ formatCurrency(form.base_price) }}</s>
                </span>
                <p class="mb-0">
                  {{ $t('sparkmembers.offers.form.to') }}
                  <strong class="text-primary">R$ {{ formatCurrency(form.price) }}</strong>
                </p>
              </div>
            </div>
          </hs-group>
        </div>
      </div>

      <hs-group>
        <label for="installments" class="font-weight-bold">{{
          $t('sparkmembers.offers.form.installments-limit')
        }}</label>
        <div class="w-25">
          <hs-multiselect
            id="installments"
            :placeholder="$t('sparkmembers.offers.form.installments-limit-placeholder')"
            :searchable="false"
            :showLabels="false"
            :options="calculateInstallments"
            v-model="form.installments_amount_limit"
            :disabled="loading"
          />
        </div>
        <div class="w-100 mt-3 text-danger" v-if="form.installments_amount_limit > calculateInstallments.length">
          {{ $t('sparkmembers.offers.form.max-parcels') }} {{ calculateInstallments.length }}
        </div>
      </hs-group>

      <hs-group>
        <label class="font-weight-bold">{{ $t('sparkmembers.offers.form.price-format') }}</label>
        <b-form-radio-group
          :options="[
            {
              text: $t('sparkmembers.offers.form.price-format-options.full'),
              value: 'full',
            },
            {
              text: $t('sparkmembers.offers.form.price-format-options.installments'),
              value: 'installments',
            },
          ]"
          v-model="form.price_format"
          :disabled="loading"
        />
      </hs-group>

      <hs-group>
        <label class="font-weight-bold">{{ $t('sparkmembers.offers.form.payment_methods.title') }}</label>
        <b-form-checkbox-group :options="paymentOptions" v-model="payment_methods" :disabled="loading" />
      </hs-group>
    </div>

    <div class="mt-4">
      <hs-checkbox v-model="form.default" :disabled="loading">
        {{ $t('sparkmembers.offers.form.default') }}
      </hs-checkbox>
      <small class="form-text text-muted">
        {{ $t('sparkmembers.offers.form.default-helper') }}
      </small>
    </div>

    <div class="d-flex w-100 justify-content-between mt-5">
      <hs-button
        variant="outline-secondary"
        class="mr-3"
        @click="onDelete"
        type="button"
        v-if="form.id"
        :disabled="loading"
      >
        {{ $t('actions.delete') }}
      </hs-button>
      <hs-button
        :variant="$v.form.$invalid || loading ? 'light' : 'primary'"
        :disabled="$v.form.$invalid || loading"
        type="submit"
        class="ml-auto"
        data-testid="form-submit"
      >
        <b-spinner small type="grow" v-if="loading" />
        {{ loading ? $t('sparkmembers.offers.form.saving') : $t('sparkmembers.offers.form.save') }}
      </hs-button>
    </div>
  </b-form>
</template>

<script>
import { required, minValue, maxValue } from 'vuelidate/lib/validators';
import { Money } from 'v-money';
import AvailablePaymentMethods from '@/data/payment_methods';
import GeneralHelper from '@/shared/helpers/general';

export default {
  props: {
    form: {
      type: Object,
      required: true,
    },
    loading: Boolean,
    showPayment: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      payment_methods: [],
      paymentOptions: AvailablePaymentMethods(key => this.$t(key)),
    };
  },
  components: { Money },
  validations() {
    return {
      form: {
        title: {
          required,
        },
        price: {
          required,
          ...(this.showPayment && { minValue: minValue(1.99) }),
        },
        installments_amount_limit: {
          required,
          maxValue: maxValue(this.calculateInstallments.length),
        },
      },
    };
  },
  methods: {
    formatCurrency(value) {
      return value ? GeneralHelper.currency(value, false) : '0,00';
    },
    onSubmit() {
      const payload = {
        ...this.form,
        payment_method: this.payment_methods.length === 2 ? null : this.payment_methods[0],
      };

      this.$emit('submit', payload);
    },
    onDelete() {
      this.$emit('delete');
    },
  },
  computed: {
    calculateInstallments() {
      let minPriceInstallments = 1.99;
      let numbInstallments = this.form.price / minPriceInstallments;
      let optionsArray = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
      if (numbInstallments < 1) {
        optionsArray = optionsArray.slice(0, 1);
      } else if (numbInstallments > 12) {
        optionsArray = optionsArray.slice(0, 12);
      } else {
        optionsArray = optionsArray.slice(0, Math.trunc(numbInstallments));
      }
      return optionsArray;
    },
    mustShowGAField() {
      return this.$FCL.hasSparkCheckout();
    },
  },
  watch: {
    'form.promotional': {
      handler(newValue) {
        if (newValue) this.form.base_price = this.form.price;
      },
      deep: true,
    },
  },
  mounted() {
    if (this.form.payment_method.length) {
      this.payment_methods.push(this.form.payment_method);
    } else {
      this.payment_methods = this.form.id ? ['credit_card', 'boleto'] : [];
    }

    if (this.form.installments_amount_limit > this.calculateInstallments.length) {
      this.form.installments_amount_limit = this.calculateInstallments.length;
    }
  },
};
</script>
