<template>
  <div>
    <hsPageHeader
      :title="$t('sparkmembers.offers.create.title')"
      :back-text="$t('sparkmembers.offers.page-header.title')"
      back-route="ProductOffers"
    />
    <div class="my-sm-5 px-sm-5">
      <div class="row">
        <div class="col-12 col-lg-7">
          <FormTabs v-model="form" :form="form" :product="product" :isSaving="isSaving" @confirm="save" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import hsPageHeader from '@/components/_structures/PageHeader';
import FormTabs from '../components/FormTabs.vue';
import { productService, analyticsService } from '@/services';
import { PaymentMethodsList } from '@/types/payments';
import ToastHelper from '@/shared/helpers/toast';
import GeneralHelper from '@/shared/helpers/general';

export default {
  data() {
    return {
      form: {
        title: null,
        price: '0.01',
        payment_method: [],
        default: null,
        promotional: null,
        period: 'unitary',
        kind: 'common',
      },
      isSaving: false,
    };
  },
  components: { hsPageHeader, FormTabs },
  computed: {
    ...mapState('product', {
      product: state => state.selectedProduct,
    }),
    ...mapState('school', ['selectedSchool']),
  },
  methods: {
    async save() {
      this.isSaving = true;
      const payload = {
        ...this.form,
        price: this.form.price > 0 ? GeneralHelper.valueInCents(this.form.price) : 0,
        base_price: this.form.base_price > 0 ? GeneralHelper.valueInCents(this.form.base_price) : 0,
        school_product_id: this.product.id,
      };
      const isFreeOffer = payload.kind === 'free';
      productService
        .createProductPaymentOption(this.product.id, payload)
        .then(({ data }) => {
          const analitcsOfferBaseProps = {
            id: data.id,
            name: payload.title,
            type: 'common',
            product_id: this.product.id,
            facebook_pixel: payload.facebook_pixel !== '',
            default: payload.default ? true : false,
            price: GeneralHelper.valueNormalizeCents(payload.promotional ? payload.base_price : payload.price),
            school_id: this.selectedSchool.id,
          };

          const trackEventType = isFreeOffer ? 'Free offer created' : 'Offer created';
          ToastHelper.successMessage(this.$t('sparkmembers.offers.create.success'));
          analyticsService.track({
            event: trackEventType,
            props: {
              ...analitcsOfferBaseProps,
              ...(!isFreeOffer && {
                credit_card: payload.payment_method.includes(PaymentMethodsList.CREDIT_CARD),
                boleto: payload.payment_method.includes(PaymentMethodsList.BANK_SLIP),
                pix: payload.payment_method.includes(PaymentMethodsList.PIX),
                promotional_price: payload.promotional ? GeneralHelper.valueNormalizeCents(payload.price) : 0.0,
                promotional: payload.promotional ? true : false,
              }),
            },
          });
          this.$router.push({ name: 'ProductOffers' });
        })
        .catch(() => {
          ToastHelper.dangerMessage(this.$t('sparkmembers.offers.create.error'));
        })
        .finally(() => {
          this.isSaving = false;
        });
    },
  },
};
</script>
