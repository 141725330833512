<template>
  <div>
    <hsPageHeader
      :title="$t('sparkmembers.offers.edit.title')"
      :back-text="$t('sparkmembers.offers.page-header.title')"
      back-route="ProductOffers"
    />
    <div class="my-sm-5 px-sm-5">
      <div class="row">
        <div class="col-12 col-lg-7 mb-5">
          <FormTabs
            v-model="form"
            :form="paymentOption"
            :product="product"
            :isSaving="isUpdating"
            @confirm="edit"
            @delete="onDelete"
          />
        </div>
        <div class="col-12 col-lg-5">
          <div class="bg-white rounded-lg py-4 px-5">
            <div class="w-100 d-flex">
              <hs-icon icon="external-link" class="fa-2x mr-3" />
              <div>
                <h6 class="font-weight-bold mb-2">
                  {{ $t('sparkmembers.offers.edit.offer-link') }}
                </h6>
                <a :href="offerLink" target="_blank" class="text-primary text-decoration-none text-break">
                  {{ offerLink }}
                </a>
                <div class="w-100">
                  <hs-button variant="outline-secondary" class="mr-3 mt-3" @click="copyToClipboard(offerLink)">
                    {{ $t('sparkmembers.offers.offers-list.card.copy') }}
                  </hs-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <input type="hidden" ref="link-checkout" />
    <hsConfirmModal
      :id="modalId"
      icon="trash-alt"
      variant="cherry"
      :customTitle="$t('sparkmembers.offers.edit.delete-modal.title')"
      :description="$t('sparkmembers.offers.edit.delete-modal.description')"
      :ok-title="$t('sparkmembers.offers.edit.delete-modal.ok-title')"
      :cancel-title="$t('sparkmembers.offers.edit.delete-modal.cancel-title')"
      @ok="remove"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import Clipboard from '@/shared/mixins/Clipboard';
import { hsConfirmModal } from '@/components';
import FormTabs from '../components/FormTabs.vue';
import hsPageHeader from '@/components/_structures/PageHeader';
import { productService, analyticsService } from '@/services';
import ToastHelper from '@/shared/helpers/toast';
import GeneralHelper from '@/shared/helpers/general';
import { makeCheckoutUrl } from '@/libs/ecommerce';

export default {
  mixins: [Clipboard],
  data() {
    return {
      form: {},
      modalId: 'delete-offer-modal',
      isUpdating: false,
      isDeleting: false,
    };
  },
  components: {
    FormTabs,
    hsConfirmModal,
    hsPageHeader,
  },
  computed: {
    ...mapGetters('school', ['getDomain']),
    ...mapState('product', {
      paymentOption: state => state.productPaymentOption,
      product: state => state.selectedProduct,
    }),
    ...mapState('school', ['selectedSchool']),
    hasSparkCheckout() {
      return this.$FCL.hasSparkCheckout();
    },
    offerLink() {
      return makeCheckoutUrl(this.hasSparkCheckout, this.paymentOption, this.product, this.getDomain);
    },
  },
  methods: {
    ...mapActions('product', ['getProductPaymentOption', 'updateProductPaymentOption']),
    async edit() {
      this.isUpdating = true;
      const payload = {
        ...this.form,
        price: this.form.price > 0 && GeneralHelper.valueInCents(this.form.price),
        base_price: this.form.base_price > 0 && GeneralHelper.valueInCents(this.form.base_price),
      };
      try {
        await this.updateProductPaymentOption(payload);
        ToastHelper.successMessage(this.$t('sparkmembers.offers.edit.success'));
        analyticsService.track({
          event: 'Offer updated',
          props: {
            id: payload.id,
            school_id: this.selectedSchool.id,
            name: payload.title,
            type: 'common',
            credit_card: payload.payment_method === null || payload.payment_method === 'credit_card',
            boleto: payload.payment_method === null || payload.payment_method === 'boleto',
            product_id: this.product.id,
            price: payload.promotional
              ? GeneralHelper.valueNormalizeCents(payload.base_price)
              : GeneralHelper.valueNormalizeCents(payload.price),
            promotional_price: payload.promotional ? GeneralHelper.valueNormalizeCents(payload.price) : 0.0,
            promotional: payload.promotional ? true : false,
            facebook_pixel: payload.facebook_pixel !== '',
            default: payload.default ? true : false,
          },
        });
        this.$router.push({ name: 'ProductOffers' });
      } catch (error) {
        ToastHelper.dangerMessage(this.$t('sparkmembers.offers.edit.error'));
      } finally {
        this.isUpdating = false;
      }
    },
    onDelete() {
      this.$bvModal.show(this.modalId);
    },
    async remove() {
      this.isDeleting = true;

      productService
        .deleteProductPaymentOption(this.paymentOption.school_product_id, this.paymentOption.id)
        .then(() => {
          ToastHelper.successMessage(this.$t('sparkmembers.offers.edit.toast.success'));
          this.$router.push({ name: 'ProductOffers' });
        })
        .catch(() => {
          ToastHelper.dangerMessage(this.$t('sparkmembers.offers.edit.toast.error'));
        })
        .finally(() => {
          this.isDeleting = false;
        });
    },
    copyToClipboard(value) {
      const ref = 'link-checkout';

      try {
        const result = this.clipboardCopy(ref, value);
        if (!result) throw new Error('Exec command failed');
        ToastHelper.successMessage(this.$t('sparkmembers.offers.offers-list.copy-link.success'));
      } catch (error) {
        ToastHelper.dangerMessage(this.$t('sparkmembers.offers.offers-list.copy-link.error'));
      }
    },
  },
  destroyed() {
    this.getProductPaymentOption({});
  },
};
</script>
