<template>
  <div class="mt-5 w-100 d-flex flex-column justify-content-center">
    <b-img-lazy
      class="no-offers-warning__image"
      :src="require('@/assets/images/SparkMembers/OffersProduct/no_offers_data.svg')"
    />
    <div class="text-center mt-4">
      <h3 class="font-weight-bold text-cherry mb-3">
        {{ $t('sparkmembers.offers.empty.title') }}
      </h3>
      <p class="mb-4" v-html="$t('sparkmembers.offers.empty.subtitle')"></p>
      <hs-button variant="primary" :to="{ name: 'ProductCreateOffers' }">
        {{ $t('sparkmembers.offers.empty.action') }}
      </hs-button>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.no-offers-warning__image {
  height: 170px;
}
</style>
