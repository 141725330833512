<template>
  <b-modal
    id="FreeOfferModal"
    v-model="modalVisible"
    size="sm"
    centered
    hide-footer
    header-class="border-0"
    body-class="pt-0 px-5"
    @hidden="onClose"
    :static="true"
  >
    <div class="d-flex flex-column align-items-center">
      <hs-icon size="50" variant="light" icon="bell" class="text-primary" />
      <h4 class="my-3 font-weight-bold">
        {{ $t('sparkmembers.offers.modal.free-offer.modal-info.title') }}
      </h4>
      <div class="mb-4 text-center">
        {{ $t('sparkmembers.offers.modal.free-offer.modal-info.content') }}
      </div>
      <hs-button variant="primary" @click="confirm">
        {{ $t('sparkmembers.offers.modal.free-offer.modal-info.confirm') }}
      </hs-button>
    </div>
  </b-modal>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      modalVisible: this.visible,
    };
  },
  methods: {
    onClose() {
      this.$emit('close');
    },
    confirm() {
      this.modalVisible = false;
      this.$emit('confirm');
    },
  },
  watch: {
    visible(visible) {
      this.modalVisible = visible;
    },
  },
};
</script>
