<template>
  <b-tabs
    v-on:activate-tab="onTabChange"
    v-model="tabIndex"
    nav-class="hs-offer__tabs bg-white px-sm-5 text-dark"
    active-nav-item-class="hs-offer__tab--active font-weight-bold"
    :fill="isMobile"
  >
    <!-- <b-tab :title="$t('sparkmembers.offers.form.tabs.paid')" title-link-class="col-12 py-3 border-0 text-dark"> -->
    <div class="bg-white rounded-lg py-4 px-3 px-sm-5">
      <Form :form="form" @submit="onFormPaidSubmited" :loading="isSaving" />
    </div>
    <!-- </b-tab> -->
    <!-- <b-tab title-link-class="py-3 border-0 text-dark position-relative">
      <template v-slot:title>
        <span class="hs-feature-flag__new position-absolute px-2 text-white font-weight-normal">
          {{ $t('feature-flag.new') }}
        </span>
        {{ $t('sparkmembers.offers.form.tabs.free') }}
      </template>

      <div class="bg-white rounded-lg py-4 px-3 px-sm-5">
        <Form :form="form" @submit="onFormFreeSubmited" :loading="isSaving" :show-payment="false" />
      </div>
    </b-tab> -->
    <FreeOfferModal
      :visible="freeOfferModalVisible"
      @confirm="onConfirmFreeOffer"
      @close="freeOfferModalVisible = false"
    />
    <FreeOfferInvalidModal :visible="freeOfferInvalidModalVisible" @close="freeOfferInvalidModalVisible = false" />
  </b-tabs>
</template>

<script>
import FreeOfferModal from './FreeOfferModal.vue';
import FreeOfferInvalidModal from './FreeOfferInvalidModal.vue';
import BrowserHelper from '@/shared/helpers/browser';
import Form from './Form.vue';
import { courseService } from '@/services';

const TAB_PAID = 0;
const TAB_FREE = 1;
export default {
  components: { FreeOfferModal, FreeOfferInvalidModal, Form },
  props: {
    product: {
      type: Object,
      required: true,
    },
    form: {
      type: Object,
      required: true,
    },
    isSaving: {
      type: Boolean,
    },
  },
  data() {
    return {
      tabIndex: this.form.kind === 'free' ? TAB_FREE : TAB_PAID,
      freeOfferModalVisible: false,
      freeOfferInvalidModalVisible: false,
      hasVideoLessons: false,
    };
  },
  async mounted() {
    const courseId = this.product.library_resource.resource.id;
    this.hasVideoLessons = await courseService.hasVideoLessons(courseId);
    if (this.tabIndex === TAB_FREE && this.hasVideoLessons) {
      this.freeOfferInvalidModalVisible = true;
      this.tabIndex = TAB_PAID;
    }
  },
  computed: {
    isMobile() {
      return BrowserHelper.isMobile();
    },
  },
  methods: {
    onFormFreeSubmited(payload) {
      this.$emit('input', {
        id: payload.id,
        title: payload.title,
        facebook_pixel: payload.facebook_pixel,
        default: payload.default,
        kind: 'free',
      });
      this.freeOfferModalVisible = true;
    },
    onFormPaidSubmited(payload) {
      this.$emit('input', {
        ...payload,
        kind: 'common',
      });
      this.$emit('confirm');
    },
    onConfirmFreeOffer() {
      this.$emit('confirm');
    },
    onTabChange(newValue, oldValue, event) {
      if (newValue === TAB_FREE && this.hasVideoLessons) {
        event.preventDefault();
        this.freeOfferInvalidModalVisible = true;
      }
    },
  },
};
</script>

<style lang="scss">
.hs-feature-flag__new {
  border-radius: 20px;
  right: -35px;
  top: 15px;
  font-size: 0.75rem;
  background: var(--cherry);
}
.hs-offer__tabs {
  border-bottom-width: 3px;
}
.hs-offer__tab--active {
  border-bottom: 3px solid var(--cherry) !important;
  margin-bottom: -2px;
}
@media only screen and (max-width: 576px) {
  .hs-feature-flag__new {
    right: 10px;
  }
}
</style>
