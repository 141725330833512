<template>
  <div class="position-relative">
    <hs-alert class="m-0" variant="warning" :show="showWarningAddWalletAlert" dismissible
      >{{ $t('sparkmembers.offers.toast.gateway') }}
      <router-link class="text-primary" :to="{ name: 'Wallet' }"
        ><u>{{ $t('sparkmembers.offers.toast.wallet') }}</u></router-link
      >
    </hs-alert>
    <hs-alert class="m-0" variant="danger" :show="showDangerProductPublished" dismissible
      >{{ $t('sparkmembers.offers.toast.published') }}
    </hs-alert>
    <hsPageHeader
      :title="$t('sparkmembers.offers.page-header.title')"
      :sub-title="$t('sparkmembers.offers.page-header.subtitle')"
      :back-text="$t('breadcrumb.ProductDashboard')"
      back-route="ProductDashboard"
    >
      <template slot="actions">
        <div class="d-flex d-lg-block flex-row flex-md-column justify-content-between">
          <hs-button
            class="col-sm-120 col-lg-auto float-none float-sm-right"
            variant="primary"
            :to="{ name: 'ProductCreateOffers' }"
          >
            {{ $t('sparkmembers.offers.page-header.add-new') }}
          </hs-button>
        </div>
      </template>
    </hsPageHeader>
    <div class="mt-5 mb-4 px-5">
      <div class="row">
        <hs-loading v-if="isLoading || isDeleting" />
        <template v-else>
          <template v-if="payment_options.length > 0">
            <div
              class="col-12 col-lg-3 col-md-6 col-sm-6 mb-4"
              v-for="(payment_option, index) in payment_options"
              :key="index"
            >
              <hs-card
                :img-src="require(`@/assets/images/SparkMembers/OffersProduct/default.svg`)"
                :badges="generateBadge(payment_option.default)"
                :actions="[]"
                class="h-100"
              >
                <div class="w-100 d-flex align-items-start justify-content-between">
                  <h4 class="card-title">{{ payment_option.title }}</h4>
                  <hs-button
                    class="p-0"
                    variant="link"
                    @click="
                      () => {
                        offerId = payment_option.id;
                        $bvModal.show('delete-offer-modal');
                      }
                    "
                  >
                    <hs-icon icon="trash" />
                  </hs-button>
                </div>
                <p class="font-weight-bold mb-0 text-cherry">{{ payment_option.price | currency }}</p>
                <p class="font-size-sm text-secondary mb-4">
                  {{ $t('sparkmembers.offers.offers-list.card.accept-only') }}
                  <strong>
                    {{ formatAcceptedPaymentOptions(payment_option.payment_method) }}
                  </strong>
                </p>
                <div class="d-flex flex-wrap">
                  <hs-button
                    variant="outline-secondary"
                    class="flex-grow-1 mr-3"
                    @click="copyToClipboard(payment_option)"
                  >
                    {{ $t('sparkmembers.offers.offers-list.card.copy') }}
                  </hs-button>
                  <hs-button variant="primary" @click="editOffer(payment_option)">
                    {{ $t('sparkmembers.offers.offers-list.card.edit') }}
                  </hs-button>
                </div>
              </hs-card>
            </div>
            <input type="hidden" ref="link-checkout" />
          </template>
          <Empty v-else />
        </template>
      </div>
    </div>
    <hsConfirmModal
      id="delete-offer-modal"
      icon="trash-alt"
      variant="cherry"
      :customTitle="$t('sparkmembers.offers.edit.delete-modal.title')"
      :description="$t('sparkmembers.offers.edit.delete-modal.description')"
      :ok-title="$t('sparkmembers.offers.edit.delete-modal.ok-title')"
      :cancel-title="$t('sparkmembers.offers.edit.delete-modal.cancel-title')"
      @hidden="() => (offerId = null)"
      @ok="removeOffer"
    />
  </div>
</template>

<script>
import hsPageHeader from '@/components/_structures/PageHeader';
import { mapState, mapActions, mapGetters } from 'vuex';
import Clipboard from '@/shared/mixins/Clipboard';
import GeneralHelper from '@/shared/helpers/general';
import ToastHelper from '@/shared/helpers/toast';
import { productService, gatewayService } from '@/services';
import { hsLoading, hsConfirmModal } from '@/components';
import Empty from './components/Empty';
import { makeCheckoutUrl } from '@/libs/ecommerce';
import { formatAcceptedPaymentOptions } from '@/libs/paymentMethods';

export default {
  mixins: [Clipboard],
  data() {
    return {
      showWarningAddWalletAlert: false,
      showDangerProductPublished: false,
      isLoading: false,
      isDeleting: false,
      payment_options: [],
      offerId: null,
    };
  },
  computed: {
    ...mapGetters('school', ['getDomain']),
    ...mapState('product', {
      product: state => state.selectedProduct,
    }),
    ...mapState('school', {
      school: state => state.selectedSchool,
    }),
    hasSparkCheckout() {
      return this.$FCL.hasSparkCheckout();
    },
  },
  components: {
    hsPageHeader,
    hsLoading,
    hsConfirmModal,
    Empty,
  },
  filters: {
    currency(value) {
      return GeneralHelper.currency(value);
    },
  },
  methods: {
    ...mapActions('product', ['getProductPaymentOptions', 'getProductPaymentOption']),
    formatAcceptedPaymentOptions,
    async fetchPaymentOptions() {
      this.isLoading = true;
      try {
        this.payment_options = await this.getProductPaymentOptions({ id: this.$route.params.id });
        this.payment_options = this.payment_options.map(payment => ({
          ...payment,
          price: payment.price / 100,
          base_price: payment.base_price / 100,
        }));

        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
      }
    },
    generateBadge(badge) {
      return badge
        ? [
            {
              label: this.$t('sparkmembers.offers.offers-list.card.badge'),
              variant: 'primary',
            },
          ]
        : [];
    },
    copyToClipboard(paymentOption) {
      const ref = 'link-checkout';
      let link = makeCheckoutUrl(this.hasSparkCheckout, paymentOption, this.product, this.getDomain);

      try {
        const result = this.clipboardCopy(ref, link);
        if (!result) throw new Error('Exec command failed');
        ToastHelper.successMessage(this.$t('sparkmembers.offers.offers-list.copy-link.success'));
      } catch (error) {
        ToastHelper.dangerMessage(this.$t('sparkmembers.offers.offers-list.copy-link.error'));
      }
    },
    editOffer(offer) {
      this.getProductPaymentOption(offer);
      this.$router.push({ name: 'ProductEditOffers', params: { offerId: offer.id } });
    },
    async removeOffer() {
      this.isDeleting = true;

      productService
        .deleteProductPaymentOption(this.product.id, this.offerId)
        .then(() => {
          ToastHelper.successMessage(this.$t('sparkmembers.offers.edit.toast.success'));
          this.fetchPaymentOptions();
        })
        .catch(() => {
          ToastHelper.dangerMessage(this.$t('sparkmembers.offers.edit.toast.error'));
        })
        .finally(() => (this.isDeleting = false));
    },
  },
  mounted() {
    this.fetchPaymentOptions();

    if (!this.product.published) this.showDangerProductPublished = true;

    // check gateway or bank account
    gatewayService.getGateway(this.school.id, [{ key: 'gateway', value: 'spark_pay' }]).then(res => {
      if (res.gateway_credentials.length === 0 || !res.gateway_credentials[0].split_rules[0].bank_account_infos)
        this.showWarningAddWalletAlert = true;
    });
  },
};
</script>
