<template>
  <b-modal
    id="FreeOfferInvalidModal"
    size="smd"
    centered
    hide-footer
    header-class="border-0"
    body-class="pt-0"
    v-model="modalVisible"
    @hidden="onClose"
    :static="true"
  >
    <div class="d-flex flex-column align-items-center">
      <hs-icon size="50" variant="light" icon="bell" class="text-cherry" />
      <h4 class="my-4 font-weight-bold text-center">
        {{ $t('sparkmembers.offers.modal.free-offer.modal-invalid.title') }}
      </h4>
      <div class="mb-4 text-center">
        {{ $t('sparkmembers.offers.modal.free-offer.modal-invalid.content') }}
      </div>
      <div class="col-12 d-block justify-content-between">
        <hs-button class="float-left" variant="outline-secondary" @click="onClose">
          {{ $t('actions.cancel') }}
        </hs-button>
        <hs-button class="float-right" variant="cherry" @click="confirm">
          {{ $t('sparkmembers.offers.modal.free-offer.modal-invalid.redirect') }}
        </hs-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      modalVisible: this.visible,
    };
  },
  methods: {
    onClose() {
      this.$emit('close');
    },
    confirm() {
      this.$router.push({
        name: 'ContentsList',
      });
      this.$emit('confirm');
      this.modalVisible = false;
    },
  },
  watch: {
    visible(visible) {
      this.modalVisible = visible;
    },
  },
};
</script>
